.TopBar{
    color:#D8A311;
    position:fixed;
    top:0;
    width:100%;
    height: 70px;
    transition: top 0.3s;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    background-color:#090a0f;
    z-index:10;
}
.navbar{
    display:flex;
    flex-direction:row;
    margin-right: 20px;
}
.navbar-main{
    margin-left:20px;
}
.navbar-main h3{
    margin:10px 0 10px 0;
}

.navbar-main a{
    cursor: pointer;
    text-decoration: none;
    font-weight:bold;
    color:#fdc029;
}

.navbar-main a:hover{
    color:#bcb6ae;
}

.navbar-options{
    display:flex;
    flex-direction:row-reverse;
    width:100%;
    margin:10px 0 10px 0;
}
.navbar-options ul{
    display:flex;
    flex-direction: row;
    list-style-type:none;
}

.navbar-options li{
    margin-left: 30px;
}

.navbar-options a{
    text-decoration: none;
    cursor:pointer;
    color:#fdc029;
}

.navbar-options a:hover{
    color:#df861d;
}

@media only screen and (max-width: 600px) {
    
    .navbar-main{
        margin-left:5px;
    }

    .navbar-options{

        width:100%;
        margin:10px 0 10px 10px;
    }

    .navbar-options ul{
        display:flex;
        flex-direction: row;
        list-style-type:none;
        padding:0;
    }
    .navbar-options li{
        margin-left: 10px;
        font-size: 13px;
    }
}



