.AboutMe{
    color:white;
    text-align: center;
    width:100%;
    padding: 5% 0 10% 0;
}


.neon h1{
    font-family: Monoton;
    font-weight: normal;
    text-shadow: 0 0 5px #e29e4f, 0 0 5px #e29e4f;
}

.neon span{
    display:block;
    border-top: 3px solid #df861d;
    margin:0 30% 0 30%;
    box-shadow:  0px 0px 20px 3px #df861d, inset 0 0 10px #df861d;
}

.AboutMe-content  div{
    font-size: x-large;
    margin: 15px 5% 30px 5%;
}

.AboutMe-history{
    display:grid;
    grid-template-columns:2fr 1fr;
    text-align:justify;
}

.AboutMe-history-parragraph{
    text-shadow: 0 0 3px #bebebe, 0 0 3px #0298fc;
}

.AboutMe-photo{
    display:flex;
    flex-direction:column;
    align-items: center;
    justify-content: center;
    height:100%;
    width:100%;
}

.border-photo{
    width: 260px;
    height: 360px;
    position: relative;
    z-index: 1;
    border-radius:5px;
    background-color: transparent;
    border: 3px solid rgb(255, 89, 191);
    box-shadow:  0px 0px 20px 3px rgb(212, 0, 131), inset 0 0 15px 3px rgb(226, 31, 151);

}

.photo{
    position: absolute;
    width: 240px;
    height: 340px;
    background-image: url("../../images/Me.png");
    z-index: 3;
    margin:0 !important;
    left:10px;
    top: 10px;
    border-radius:5px;
    background-position: center;
    box-shadow:  inset 0 0 5px 1px rgb(226, 31, 151);
}


.AboutMe-return{
    display:flex;
    justify-content: center;
}
.AboutMe-return div{
    background-color:transparent;
    border-radius: 5px;
    font-size: 25px;
    color:gray;
    border:3px solid;
    width:auto;
    height:auto;
    
}
.AboutMe-return p{
    margin: 15px;
    
}

.AboutMe-return div:hover{
    cursor: pointer;
    color:#df861d;
    box-shadow:  0px 0px 20px 3px #df861d, inset 0 0 10px #df861d;
    text-shadow: 0 0 5px #e29e4f, 0 0 5px #e29e4f;
}


  @media only screen and (max-width: 600px) {
    .AboutMe{
        display:flex;
        flex-direction: column;
    }

    .AboutMe-history{
        display:grid;
        grid-template-columns:1fr;
        text-align:justify;
        
    }

    .AboutMe-history p{ 
        font-size:14px;
    }
    .AboutMe-photo{
        grid-row: 1;
        margin: 0!important;
    }

    .neon span{
        margin:0 10% 0 10%;
    }

    .border-photo{
        width: 220px;
        height: 320px;
    }
    
    .photo{
        background-size:cover;
        width: 200px;
        height: 300px;
    }

  }